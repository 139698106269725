import { Injectable } from "@angular/core";

import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { environment } from "../../../environments/environment";
import { ApplicantDocumentModel } from "services/models/hiring/applicantDocument.model";
import { Observable } from "rxjs";

@Injectable()
export class ApplicantDocumentService extends ServiceBase<ApplicantDocumentModel> {
  constructor(http: HttpService) {
    super("applicantdocument", http, environment.apiUrl);
  }

  createEntity(input: any): ApplicantDocumentModel {
    return ApplicantDocumentModel.create(input);
  }

  getFile(id: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .get(
          `${this.endpoint}/s3-file/${id}`,
          { responseType: "blob" },
          null,
          null,
          true
        )
        .subscribe(
          (res) => {
            observer.next(res.body);
            observer.complete();
          },
          (err) => observer.error(err)
        );
    });
  }

  getAllApprovedFiles(applicantId: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .get(
          `${this.endpoint}/get-approved-files/${applicantId}`,
          { responseType: "blob" },
          null,
          null,
          true
        )
        .subscribe(
          (res) => {
            observer.next(res.body);
            observer.complete();
          },
          (err) => observer.error(err)
        );
    });
  }

  changeDocumentStatus(form: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.endpoint}/document-status`, form)
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }
}
