import { customPatternWithNumbers, removeAcento, schoolingOptions } from './../../../../../utils/helps';
import { OptionModel } from './../../../../services/models/model';
import { ApplicantDependentModel } from '../../../../services/models/hiring/applicantDependent.model';
import { DatePipe } from '@angular/common';
import { ApplicantService } from 'services/hiring/applicant.service';
import { GeographyService } from '../../../../services/geography.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ElementRef,
} from '@angular/core';
import { ApplicantFormModel } from '../../../../services/models/hiring/applicantForm.model';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { FormValidations } from 'utils/form-validation';
import { comparer, customPattern, omit_special_char } from 'utils/helps';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { HiringConfigModel } from 'services/models/hiring/hiringConfig.model';

@Component({
  selector: 'app-admission-form-base',
  templateUrl: './admission-form-base.component.html',
  styleUrls: ['./admission-form-base.component.scss'],
})
export class AdmissionFormBaseComponent implements OnInit {
  @Output() changedSexEvent: EventEmitter<any> = new EventEmitter();
  @Output() changedDependentEvent: EventEmitter<any> = new EventEmitter();
  @Output() formValidEvent: EventEmitter<any> = new EventEmitter();

  error = false;
  cpf = '';
  applicantId = '';
  form = new UntypedFormGroup({});
  dependentsform = new UntypedFormGroup({});
  hiringConfig: HiringConfigModel = new HiringConfigModel();
  applicant: ApplicantFormModel;
  applicantForm: ApplicantFormModel = new ApplicantFormModel();
  states: [] = [];
  places: [] = [];
  countries: [] = [];
  bankOptions: OptionModel[] = [];
  customizedBankOptions: OptionModel[] = [
    { value: 'BSC', label: `BSC - BS-CASH` },
  ];
  isStranger = false;
  stateSelected = '';
  dependentNumber = 0;
  omitChar = omit_special_char;
  compareValues = comparer;
  removeSpecialCharacter = removeAcento;
  pattern = customPattern;
  numberPattern = customPatternWithNumbers;
  datePipe = new DatePipe('pt-BR');
  isLoading = false;
  schoolingOptions = schoolingOptions;

  get birthStateField() {
    return this.form.get('birthState') as UntypedFormControl;
  }

  get birthPlaceField() {
    return this.form.get('birthplace') as UntypedFormControl;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private geographyService: GeographyService,
    private _applicantService: ApplicantService,
    private _route: ActivatedRoute,
    private el: ElementRef
  ) { }

  ngOnInit() { }

  public startComponent(disable: boolean) {
    this.loadCountries();
    this.loadStates();
    this.loadBanks();
    this.initializeForm();
    this.settleApplicantFields();
    if (disable) { this.form.disable(); }
    this.initializeFormDependents();
    this.settleApplicantDependentsFields();
    this.listinerFieldsChanges();
    if (disable) {
      setTimeout(() => {
        this.dependentsform.disable();
      }, 1500);
    }
    this.formValidEvent.emit();
  }

  settleApplicantFields() {
    setTimeout(() => {
      this.applicant.formatDates();
      this.dependentNumber = this.applicant.dependentNumber;
      this.form.patchValue({
        ...this.applicant,
        applicantId: this.applicant.id,
        nationality: this.geographyService.defaultCountry,
        bankNumber: this.applicant.bankNumber ? this.applicant.bankNumber : this.hiringConfig.bankNumbers[0],
      });
    }, 1500);
  }

  
  settleApplicantDependentsFields() {
    setTimeout(() => {
      this.dependentsFrmArray.controls.forEach((frmGrp, i) => {
        const dependent = ApplicantDependentModel.create(
          this.applicant.dependents[i]
        );
        dependent.formatDates();
        this.applicant.dependents[i] = dependent;
        frmGrp.patchValue(dependent);
      });
    }, 1700);
  }

  setLoadSpinner(state: boolean) {
    this.isLoading = state;
  }

  loadStates() {
    this.geographyService.getUfs().subscribe((states: []) => {
      this.states = states;
    });
  }

  loadCountries() {
    this.geographyService.getCountries().subscribe((countries: []) => {
      this.countries = countries;
    });
  }

  loadBanks() {
    this.geographyService.getBanks().subscribe((bank) => {
      const fetchedBankOptions = bank.data
        .filter(x => this.hiringConfig.bankNumbers.includes(x.code.toString()))
        .map(x => {
          return { value: x.code.toString(), label: `${x.code} - ${x.fullName}` };
        });
  
      const customizedBankOptions = this.customizedBankOptions
        .filter(x => this.hiringConfig.bankNumbers.includes(x.value))
        .map(x => {
          return { value: x.value, label: x.label };
        });
  
      this.bankOptions = [...fetchedBankOptions, ...customizedBankOptions];
    });
  }

  listinerFieldsChanges() {
    this.cepChanged();
    this.sexChanged();
    this.dependentChanged();
    this.ufChanged();
    this.strangerChecked();
  }

  cepChanged() {
    const field = this.form.get('zipCode') as UntypedFormControl;
    field.valueChanges.subscribe((cep: string) => {
      if (cep.length === 8 && this.applicant.zipCode !== cep) {
        this.geographyService.search(cep).subscribe(({ data }) => {
          const log = this.removeSpecialCharacter(data.logradouro);
          const neigh = this.removeSpecialCharacter(data.bairro);
          const cit = this.removeSpecialCharacter(data.localidade);
          const address = {
            street: log,
            neighborhood: neigh,
            city: cit,
            state: data.uf,
          };
          this.form.patchValue(address);
        });
      }
    });
  }

  sexChanged() {
    const field = this.form.get('sex') as UntypedFormControl;
    field.valueChanges.subscribe((sex: number) => {
      this.applicant.sex = sex;
      if (this.applicant.sex == 1) {
        this.form.get('militaryDischarge').setValidators([Validators.required]);
        this.form.get('militaryDischarge').updateValueAndValidity();
      } else {
        this.form.get('militaryDischarge').clearValidators();
        this.form.get('militaryDischarge').updateValueAndValidity();
      }
      this.changedSexEvent.emit(this.applicant);
    });
  }

  dependentChanged() {
    const field = this.form.get('dependentNumber') as UntypedFormControl;
    field.valueChanges.subscribe((dependents: number) => {
      this.applicant.dependentNumber = dependents;
      this.dependentNumber = dependents;
      this.initializeFormDependents();
      this.changedDependentEvent.emit(this.applicant);
    });
  }

  ufChanged() {
    this.birthStateField.valueChanges
      .pipe(
        tap(() => this.setLoadSpinner(true)),
        switchMap((state) => this.geographyService.getPlacesByUF(state))
      )
      .subscribe((cities: []) => {
        this.places = cities;
        this.setLoadSpinner(false);
      });
  }

  strangerChecked() {
    const field = this.form.get('stranger') as UntypedFormControl;
    field.valueChanges.subscribe((stranger: boolean) => {
      this.isStranger = field.value;
      if (this.isStranger) {
        this.birthPlaceField.clearValidators();
        this.birthStateField.clearValidators();
      }
    });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      birthDate: ['', [Validators.required]],
      maritalStatus: ['', [Validators.min(1)]],
      sex: [3, [Validators.min(1)]],
      dependentNumber: ['', [Validators.maxLength(3)]],
      racialDistinction: [8],
      schooling: [35],
      fathersName: [''],
      mothersName: ['', [Validators.minLength(5), Validators.required]],
      bankNumber: [this.hiringConfig.bankNumbers[0]],
      bankAgency: [''],
      bankAccountNumber: [''],
      bankAccountDigit: [''],
      nationality: [this.geographyService.defaultCountry],
      stranger: [false],
      birthplace: ['', [Validators.required]],
      birthState: [''],
      zipCode: [''],
      street: [''],
      addressComplement: ['', [Validators.maxLength(80)]],
      city: [''],
      state: [''],
      neighborhood: [''],
      houseNumber: [''],
      email: ['', [Validators.required, Validators.email]],
      contact1: ['', [Validators.required]],
      contact2: [''],
      electoralCard: ['', [Validators.minLength(6)]],
      electoralSection: [''],
      electoralZone: [''],
      cpf: [{ value: this.applicant.cpf, disabled: true }, [Validators.required]],
      rg: ['', [Validators.minLength(6), Validators.required]],
      rgIssuer: [''],
      rgState: [''],
      rgDate: [''],
      pis: ['', [Validators.minLength(10), Validators.required, FormValidations.pisValidator]],
      ctpsNumber: ['', [Validators.minLength(7)]],
      ctpsSerie: ['', [Validators.minLength(4)]],
      ctpsState: [''],
      ctpsIssueDate: [''],
      militaryDischarge: [''],
      applicantId: [this.applicant.id],
    });

    // Seta Validators.required de forma dinâmica para os controles específicos
    const requiredFields = JSON.parse(this.hiringConfig.setAdmissionForm);
    Object.keys(this.form.controls).forEach((field) => {
      // Se não estiver no JSON, ou se estiver e for true vai setar o required true;
      if (!requiredFields.hasOwnProperty(field) || requiredFields[field] === true) {
        const control = this.form.get(field);
        const currentValidators = control.validator;
        if (currentValidators) {
          const newValidators = [Validators.required, currentValidators];
          control.setValidators(newValidators);
        } else {
          control.setValidators(Validators.required);
        }
      }
    });

    this.form.updateValueAndValidity();
  }

  initializeFormDependents() {
    this.dependentsform = this.formBuilder.group({
      dependents: this.formBuilder.array([]),
    });
    for (let index = 0; index < this.dependentNumber; index++) {
      const dependentForm = this.formBuilder.group({
        birthDate: ['', [Validators.required]],
        sex: ['', [Validators.required]],
        cpf: ['', [Validators.maxLength(11), FormValidations.cpf, this.duplicateCpf()]],
        name: ['', [Validators.required]],
        kinshipDegree: ['', [Validators.maxLength(2), Validators.min(1)]],
        applicantId: [this.applicant.id, [Validators.required]],
        id: ['00000000-0000-0000-0000-000000000000'],
      });
      this.dependentsFrmArray.push(dependentForm);
    }
  }

  get dependentsFrmArray(): UntypedFormArray {
    return this.dependentsform.controls['dependents'] as UntypedFormArray;
  }

  duplicateCpf(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return (this.applicant.cpf === value) ? { duplicateCpf: true } : null;
    };
  }
}
