import { BaseEnum } from "./baseEnum"

const listEnum: Array<HiringStatusType> = [];

export class HiringStatusType extends BaseEnum {
  static Expired = new HiringStatusType(0, 'Expired', 'Expirado', 'danger');
  static WaitingSesmt = new HiringStatusType(1, 'WaitingSesmt', 'Aguardando Sesmt', 'secondary');
  static WaitingApplicant = new HiringStatusType(2, 'WaitingApplicant', 'Aguardando Candidato', 'info');
  static WaitingDP = new HiringStatusType(3, 'WaitingDP', 'Aguardando DP', 'warning');
  static InvalidDocs = new HiringStatusType(4, 'InvalidDocs', 'Reenvio de documentos', 'danger');
  static Finished = new HiringStatusType(5, 'Finished', 'Finalizado', 'success');
  static Integrated = new HiringStatusType(6, 'Integrated', 'Integrado', 'primary');
  static Incomplete = new HiringStatusType(7, 'Incomplete', 'Incompleto', 'secondary');
  static AwaitingOfferLetterAcceptance = new HiringStatusType(8, 'AwaitingOfferLetterAcceptance', 'Aguardando Aceite da Carta', 'secondary');
  static OfferLetterDeclined = new HiringStatusType(9, 'OfferLetterDeclined', 'Carta de Oferta Recusada', 'danger');
  static Withdrawal = new HiringStatusType(10, 'Withdrawal', 'Desistência', 'warning');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }

  public static findByValue(value: string): HiringStatusType | undefined {
    return listEnum.find((status) => status.value === value) as HiringStatusType;
  }
}