<form *ngIf="applicant" class="form form-horizontal" #frm [formGroup]="form">
  <div class="row">
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="birthDate">Data Nascimento</label>
        <input type="text" id="birthDate" [mask]="'00/00/0000'" [dropSpecialCharacters]="false"
          placeholder="dia/Mes/Ano" formControlName="birthDate" required class="form-control" />
        <app-error-msg [control]="form.controls['birthDate']" [label]="'birthDate'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-8">
      <div class="form-group">
        <label for="maritalStatus">Estado Civil</label>
        <select id="maritalStatus" formControlName="maritalStatus" required placeholder="Estado Civil"
          class="form-control">
          <option [value]="1"> Solteiro(a) </option>
          <option [value]="2"> Casado (a)</option>
          <option [value]="3"> Separado (a)</option>
          <option [value]="4"> Divorciado (a)</option>
          <option [value]="5"> Viúvo (a)</option>
          <option [value]="6"> União estável</option>
        </select>
        <app-error-msg [control]="form.controls['maritalStatus']" [label]="'maritalStatus'"></app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-4">
      <div class="form-group">
        <label for="gender">Sexo</label>
        <select id="gender" formControlName="sex" required placeholder="Sexo" class="form-control">
          <option [value]="1"> Masculino </option>
          <option [value]="2"> Feminino </option>
          <option [value]="3"> Indefinido </option>
        </select>
        <app-error-msg [control]="form.controls['sex']" [label]="'sex'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="dependents">Numero de dependentes</label>
        <input type="text" formControlName="dependentNumber" [mask]="'99'" id="dependents" required
          class="form-control" />
        <app-error-msg [control]="form.controls['dependentNumber']" [label]="'dependentNumber'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <div class="custom-control custom-switch">
        <p class="mb-50">Estrangeiro</p>
        <input type="checkbox" class="custom-control-input" id="stranger" formControlName="stranger" />
        <label class="custom-control-label" for="stranger">
          <span class="switch-icon-left"><i data-feather="check"></i></span>
          <span class="switch-icon-right"><i data-feather="x"></i></span>
        </label>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="nationality">Nacionalidade</label>
        <select id="nationality" required formControlName="nationality" required class="form-control">
          <option *ngFor="let country of countries" [value]="country.nome">{{country.nome}}</option>
        </select>
        <app-error-msg [control]="form.controls['nationality']" [label]="'nationality'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-2 col-12">
      <div class="form-group">
        <label class="form-control-label" for="uf">UF de Origem</label>
        <select *ngIf="!isStranger" id="uf" required formControlName="birthState" required class="form-control">
          <option selected value="">Selecione</option>
          <option *ngFor="let uf of states" [value]="uf.sigla">{{uf.sigla}}</option>
        </select>
        <input *ngIf="isStranger" type="text" maxlength="10" formControlName="birthState" id="birthState"
          class="form-control" />
        <app-error-msg [control]="form.controls['birthState']" [label]="'birthState'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="birthplace">Naturalidade</label>
        &nbsp;<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
        <select *ngIf="!isStranger" id="birthplace" required formControlName="birthplace" required class="form-control">
          <option selected value="">Selecione</option>
          <option *ngFor="let place of places" [value]="place.nome">{{place.nome}}</option>
        </select>
        <input *ngIf="isStranger" type="text" maxlength="30" formControlName="birthplace" id="birthplace"
          class="form-control" />
        <app-error-msg [control]="form.controls['birthplace']" [label]="'birthplace'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-2 col-12">
      <div class="form-group">
        <label class="form-control-label" for="racialDistinction">Raça/Cor</label>
        <select id="racialDistinction" formControlName="racialDistinction" placeholder="Raça/Cor" class="form-control">
          <option [value]="1"> Indígena </option>
          <option [value]="2"> Branca </option>
          <option [value]="4"> Preta </option>
          <option [value]="6"> Amarela </option>
          <option [value]="8"> Parda </option>
          <option [value]="9"> Não Informado </option>
          <option [value]="0"> Anonimizado </option>
        </select>
        <app-error-msg [control]="form.controls['racialDistinction']" [label]="'racialDistinction'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="schooling">Escolaridade</label>
        <select id="schooling" formControlName="schooling" placeholder="Escolaridade" class="form-control">
          <option *ngFor="let option of schoolingOptions" [value]="option.value">{{option.label}}</option>
        </select>
        <app-error-msg [control]="form.controls['schooling']" [label]="'schooling'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="mothersName">Nome Mãe</label>
        <input type="text" [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="pattern"
          [mask]="'B{100}'" formControlName="mothersName" name="mothersName" id="mothersName" class="form-control"
         placeholder="Nome mãe" uppercase/>
        <app-error-msg [control]="form.controls['mothersName']" [label]="'mothersName'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="fathersName">Nome Pai</label>
        <input type="text" [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="pattern"
          [mask]="'B{100}'" name="fathersName" formControlName="fathersName" class="form-control"
         placeholder="Caso não tenha deixar em branco" uppercase/>
        <app-error-msg [control]="form.controls['fathersName']" [label]="'fathersName'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="bankNumber">Banco</label>
        <ng-select [compareWith]="compareValues" formControlName="bankNumber" placeholder="Selecione">
          <ng-option *ngFor="let bank of bankOptions" [value]="bank.value">{{bank.label}}</ng-option>
        </ng-select>
        <app-error-msg [control]="form.controls['bankNumber']" [label]="'bankNumber'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="bankAgency">Agência</label>
        <input type="text" name="bankAgency" formControlName="bankAgency" class="form-control"
          (keypress)="omitChar($event)" placeholder="00000" [mask]="'99999'" />
        <app-error-msg [control]="form.controls['bankAgency']" [label]="'bankAgency'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="bank">Conta</label>
        <input type="text" name="bankAccountNumber" formControlName="bankAccountNumber" class="form-control"
          (keypress)="omitChar($event)" placeholder="00000000" [mask]="'999999999999'">
        <app-error-msg [control]="form.controls['bankAccountNumber']" [label]="'bankAccountNumber'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="form-group">
        <label class="form-control-label" for="bank">Dígito</label>
        <input type="text" name="bankAccountDigit" formControlName="bankAccountDigit" class="form-control"
          (keypress)="omitChar($event)" placeholder="0" [mask]="'9'">
        <app-error-msg [control]="form.controls['bankAccountDigit']" [label]="'bankAccountDigit'">
        </app-error-msg>
      </div>
    </div>
  </div>
  <div class="divider divider-primary">
    <div class="divider-text">Endereço e Contato</div>
  </div>
  <div class="row">
    <div class="col-md-2 col-12">
      <div class="form-group">
        <label class="form-control-label" for="zipCode">Cep</label>
        <input type="text" [dropSpecialCharacters]="true" (keypress)="omitChar($event)" [mask]="'00000-000'"
        formControlName="zipCode" name="zipCode" id="zipCode" class="form-control" placeholder="CEP" />
        <app-error-msg [control]="form.controls['zipCode']" [label]="'zipCode'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-5 col-12">
      <div class="form-group">
        <label class="form-control-label">Rua</label>
        <input class="form-control" formControlName="street" placeholder="Rua ABC XYZ" uppercase
        [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="numberPattern" [mask]="'C{40}'"/>
        <app-error-msg [control]="form.controls['street']" [label]="'street'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-5 col-12">
      <div class="form-group">
        <label class="form-control-label">Complemento</label>
        <input class="form-control" formControlName="addressComplement" placeholder="BLOCO E AP 202" uppercase
        [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="numberPattern" [mask]="'C{80}'"/>
        <app-error-msg [control]="form.controls['addressComplement']" [label]="'addressComplement'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-2 col-12">
      <div class="form-group">
        <label class="form-control-label">Número</label>
        <input class="form-control" formControlName="houseNumber" placeholder="XXX"
        [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [mask]="'999999'"/>
        <app-error-msg [control]="form.controls['houseNumber']" [label]="'houseNumber'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label">Bairro</label>
        <input class="form-control" formControlName="neighborhood" placeholder="Bairro" uppercase
        [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="pattern" [mask]="'B{40}'"/>
        <app-error-msg [control]="form.controls['neighborhood']" [label]="'neighborhood'">
        </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label">Cidade</label>
        <input class="form-control" formControlName="city" placeholder="Cidade" uppercase
        [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="pattern" [mask]="'B{40}'"/>
        <app-error-msg [control]="form.controls['city']" [label]="'city'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-2 col-12">
      <div class="form-group">
        <label class="form-control-label">Estado</label>
        <select required formControlName="state" required class="form-control">
          <option *ngFor="let uf of states" [value]="uf.sigla">{{uf.sigla}}</option>
        </select>
        <app-error-msg [control]="form.controls['state']" [label]="'state'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label">Email</label>
        <input class="form-control" formControlName="email"
          placeholder="email@mail.com" />
        <app-error-msg [control]="form.controls['email']" [label]="'email'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label">Contato 1</label>
        <input class="form-control" formControlName="contact1" [mask]="'(00) 0000-0000||(00) 0 0000-0000' "
          placeholder="(XX) X XXXX-XXXX" />
        <app-error-msg [control]="form.controls['contact1']" [label]="'contact1'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label">Contato 2</label>
        <input class="form-control" formControlName="contact2" [mask]="'(00) 0000-0000||(00) 0 0000-0000' "
          placeholder="Contato de emergência: amigo ou parente." />
        <app-error-msg [control]="form.controls['contact2']" [label]="'contact2'"> </app-error-msg>
      </div>
    </div>
  </div>
  <div class="divider divider-primary">
    <div class="divider-text">Documentação</div>
  </div>
  <div class="row">
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="electoralCard">Título de Eleitor</label>
        <input type="text" formControlName="electoralCard" [mask]="'9{14}'" name="electoralCard" id="electoralCard"
          class="form-control" placeholder="Título de Eleitor" />
        <app-error-msg [control]="form.controls['electoralCard']" [label]="'electoralCard'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="electoralSection">Seção</label>
        <input type="text" formControlName="electoralSection" [mask]="'9{4}'" name="electoralSection" id="electoralSection"
          class="form-control" placeholder="Seção de Votação" />
        <app-error-msg [control]="form.controls['electoralSection']" [label]="'electoralSection'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="electoralZone">Zona Eleitoral</label>
        <input type="text" formControlName="electoralZone" [mask]="'9{4}'" name="electoralZone" id="electoralZone"
          class="form-control" placeholder="Zona Eleitoral" />
        <app-error-msg [control]="form.controls['electoralZone']" [label]="'electoralZone'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="rg">RG</label>
        <input type="text" formControlName="rg" [mask]="'999999999A99999||999999999-A'" name="rg" id="rg"
          class="form-control" placeholder="RG" />
        <app-error-msg [control]="form.controls['rg']" [label]="'rg'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
        <label class="form-control-label" for="rgIssuer">Orgão Emissor</label>
        <input type="text" formControlName="rgIssuer" (keypress)="omitChar($event)" [patterns]="pattern" [mask]="'B{8}'"
          name="rgIssuer" id="rgIssuer" class="form-control" placeholder="SSP" uppercase/>
        <app-error-msg [control]="form.controls['rgIssuer']" [label]="'rgIssuer'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
        <label class="form-control-label" for="rgUf">UF</label>
        <select required formControlName="rgState" class="form-control">
          <option *ngFor="let uf of states" [value]="uf.sigla">{{uf.sigla}}</option>
        </select>
        <app-error-msg [control]="form.controls['rgState']" [label]="'rgState'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="rgDate">Data Emissão RG</label>
        <input type="text" [mask]="'00/00/0000'" [dropSpecialCharacters]="false" placeholder="dia/Mes/Ano"
          formControlName="rgDate" name="rgDate" id="rgDate" class="form-control" />
        <app-error-msg [control]="form.controls['rgDate']" [label]="'rgDate'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <label class="form-control-label" for="pis">PIS</label>
        <input type="text" formControlName="pis" [mask]="'000.00000.00-0||000.00000.00'" name="pis" id="pis"
          class="form-control" placeholder="PIS" />
        <app-error-msg [control]="form.controls['pis']" [label]="'pis'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="form-group">
        <span [data-feather]="'info'"></span>
        <label class="form-control-label pointer" for="ctpsNumber"
        popoverTitle="CTPS DIGITAL" ngbPopover="Se CTPS Digital, informar 7 primeiros dígitos do CPF" 
        triggers="click:blur">&nbsp;Nº CTPS</label>
        <input type="text" formControlName="ctpsNumber" [mask]="'0000000'" name="ctpsNumber" id="ctpsNumber"
          class="form-control" placeholder="0000000" />
        <app-error-msg [control]="form.controls['ctpsNumber']" [label]="'ctpsNumber'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
          <span [data-feather]="'info'"></span>
          <label class="form-control-label pointer" for="ctpsSerie" 
          popoverTitle="CTPS DIGITAL" ngbPopover="Se CTPS Digital, informar 4 últimos dígitos do CPF" 
          triggers="click:blur">&nbsp;Série CTPS</label>
        <input type="text" formControlName="ctpsSerie" [mask]="'0000'" name="ctpsSerie" id="ctpsSerie"
          class="form-control" placeholder="0000" />
        <app-error-msg [control]="form.controls['ctpsSerie']" [label]="'ctpsSerie'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
        <label class="form-control-label" for="ctpsState">UF CTPS</label>
        <select required formControlName="ctpsState" required class="form-control">
          <option *ngFor="let uf of states" [value]="uf.sigla">{{uf.sigla}}</option>
        </select>
        <app-error-msg [control]="form.controls['ctpsState']" [label]="'ctpsState'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
        <label class="form-control-label" for="ctpsIssueDate">Data Emissão CTPS</label>
        <input type="text" [mask]="'00/00/0000'" [dropSpecialCharacters]="false" placeholder="dia/Mes/Ano"
          formControlName="ctpsIssueDate" name="ctpsIssueDate" id="ctpsIssueDate" class="form-control" />
        <app-error-msg [control]="form.controls['ctpsIssueDate']" [label]="'ctpsIssueDate'"> </app-error-msg>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="form-group">
        <span [data-feather]="'info'"></span>
        <label class="form-control-label pointer" for="cpf"
        popoverTitle="PARA CONFERIR" ngbPopover="Caso haja divergência no CPF informado, favor comunicar ao RH." 
        triggers="click:blur">&nbsp;CPF</label>
        <input type="text" formControlName="cpf" [mask]="'000.000.000-00'" name="cpf" id="cpf"
          class="form-control"/>
      </div>
    </div>
    <div *ngIf="this.applicant.sex == 1" class="col-md-4 col-6">
      <div class="form-group">
        <label class="form-control-label" for="ctpsState">Reservista</label>
        <input type="text" formControlName="militaryDischarge" [mask]="'00.000.000000.0'" name="militaryDischarge" id="militaryDischarge"
          class="form-control" placeholder="Certificado de Dispensa Militar" />
        <app-error-msg [control]="form.controls['militaryDischarge']" [label]="'militaryDischarge'"> </app-error-msg>
      </div>
    </div>
  </div>
</form>
<div *ngIf="dependentNumber>0" class="divider divider-primary">
  <div class="divider-text">Dependentes</div>
</div>
<div *ngIf="dependentNumber>0" [formGroup]="dependentsform" class="form form-horizontal">
  <ng-container *ngIf="dependentsFrmArray.controls.length>0" formArrayName="dependents">
    <ng-container *ngFor="let dependentForm of dependentsFrmArray.controls; let i = index">
      <div [formGroup]="dependentForm" class="row">
        <div class="col-md-9 col-12">
          <div class="form-group">
            <label class="form-control-label" for="name">Nome </label>
            <input type="text" [dropSpecialCharacters]="false" (keypress)="omitChar($event)" [patterns]="pattern"
              [mask]="'B{100}'" class="form-control" formControlName="name" required placeholder="Nome" uppercase/>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label class="form-control-label" for="birthDate">Data Nascimento</label>
            <input type="text" [mask]="'00/00/0000'" [dropSpecialCharacters]="false" placeholder="dia/Mes/Ano"
              formControlName="birthDate" required class="form-control" />
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label class="form-control-label" for="kinshipDegree">Grau de Parentesco</label>
            <select id="kinshipDegree" formControlName="kinshipDegree" required placeholder="Grau de Parentesco"
              class="form-control">
              <option [value]="'C'"> Cônjuge/Companheiro </option>
              <option [value]="'F'"> Filho</option>
              <option [value]="'E'"> Enteado</option>
              <option [value]="'P'"> Pai/Mãe</option>
              <option [value]="'O'"> Agregado/Outros</option>
            </select>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label for="gender">Sexo</label>
            <select id="gender" formControlName="sex" required placeholder="Sexo" class="form-control">
              <option [value]="1"> Masculino </option>
              <option [value]="2"> Feminino </option>
              <option [value]="3"> Indefinido </option>
            </select>
            <app-error-msg [control]="form.controls['sex']" [label]="'sex'"> </app-error-msg>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label class="form-control-label" for="cpf">CPF</label>
            <input type="text" [mask]="'000.000.000-00'" class="form-control" required formControlName="cpf"
              placeholder="CPF" />
            <app-error-msg [control]="dependentForm.controls['cpf']" [label]="'cpf'"> </app-error-msg>
            <span *ngIf="dependentForm.get('cpf')?.errors?.duplicateCpf">
              <small class="form-text text-danger">
                Mesmo CPF do candidato
              </small>
            </span>
          </div>
        </div>
      </div>
      <div class="divider ">
        <div class="divider-text"></div>
      </div>
    </ng-container>
  </ng-container>
</div>