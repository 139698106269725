import { ObjectMapper } from 'json-object-mapper';

export class ReportHiringIndiceModel {
  countWaitWorksafety: number = 0;
  countWaitApplicant: number = 0;
  countWaitApproval: number = 0;
  countInvalidDocs: number = 0;
  countFinished: number = 0;
  countOverdue: number = 0;
  countIncomplete: number = 0;
  countWithdrawal: number = 0;
  countAwaitingOfferLetterAcceptance: number = 0;
  countOfferLetterDeclined: number = 0;
  timeAvgWaitWorksafety: number = 0;
  timeAvgWaitApplicant: number = 0;
  timeAvgWaitApproval: number = 0;
  timeAvgInvalid_docs: number = 0;
  timeAvgIncomplete: number = 0;
  timeAvgAwaitingOfferLetterAcceptance: number = 0;
  countNotFilledSexApplicant: number = 0;
  countMaleSexApplicant: number = 0;
  countFemaleSexApplicant: number = 0;
  countIndefiniteSexApplicant: number = 0;
  countIlliterateSchooling: number = 0;
  countIncompletePrimarySchooling: number = 0;
  countCompletePrimarySchooling: number = 0;
  countIncompleteGymnasiumSchooling: number = 0;
  countCompleteGymnasiumSchooling: number = 0;
  countIncompleteHighSchoolSchooling: number = 0;
  countCompleteHighSchoolSchooling: number = 0;
  countIncompleteHigherSchooling: number = 0;
  countGraduatedSchooling: number = 0;
  countCompleteMasterDegreeSchooling: number = 0;
  countCompleteDoctorateSchooling: number = 0;
  countPostgraduateSpecializationSchooling: number = 0;
  countPostDoctoralSchooling: number = 0;
  countUpTo19Years: number = 0;
  countFrom20To25Years: number = 0;
  countFrom26To30Years: number = 0;
  countFrom31To35Years: number = 0;
  countFrom36To40Years: number = 0;
  countFrom41To50Years: number = 0;
  countFrom51To60Years: number = 0;
  countOver60Years: number = 0;
  countUpTo1320: number = 0;
  countFrom1321To2100: number = 0;
  countFrom2101To3100: number = 0;
  countFrom3101To5100: number = 0;
  countFrom5101To8100: number = 0;
  countMoreThan8100: number = 0;

  static create(input: any): ReportHiringIndiceModel {
    return ObjectMapper.deserialize<ReportHiringIndiceModel>(ReportHiringIndiceModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
