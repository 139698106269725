import { Injectable } from "@angular/core";
import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { map } from "rxjs/operators";
import { TimeShiftModel } from "services/models/hiring/timeShift.model";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { SequenceTimeShiftModel } from "services/models/hiring/sequenceTimeShift.model";
import { TimeShiftHistoryModel } from "services/models/corporate/timeShiftHistory.model";
import { DynamicModel } from "services/models/dynamic.model";
import { environment } from "environments/environment";
import buildQuery from 'odata-query';

@Injectable()
export class TimeShiftService extends ServiceBase<TimeShiftModel> {
	constructor(http: HttpService) {
		super("timeshift", http, environment.apiUrl);
	}

	createEntity(input: any): TimeShiftModel {
		return TimeShiftModel.create(input);
	}

	getSequencesByTimeShift(timeShiftId: string): Observable<Response<SequenceTimeShiftModel[]>> {
		return this.http
			.get(`${this.endpoint}/${timeShiftId}/sequences`)
			.pipe(
				map((res) => {
					const response = new Response<SequenceTimeShiftModel[]>();
					response.data = res.body.map((pp) => SequenceTimeShiftModel.create(pp) as SequenceTimeShiftModel);
					response.count = res.headers.get('x-count');
					return response;
				})
			);
	}

	getTableSequenceOptions(date: string, timeShiftId: string): Observable<Response<DynamicModel>> {
		return this.http
			.get(`${this.endpoint}/${date}/${timeShiftId}/table-sequence-options`)
			.pipe(
				map((res) => {
					const response = new Response<DynamicModel>();
					response.data = DynamicModel.create(res.body) as DynamicModel;
					response.count = res.headers.get('x-count');
					return response;
				})
			);
	}

	listByEmployee(employeeId: string): Observable<Response<TimeShiftHistoryModel[]>> {
		return this.http.get(`${this.endpoint}/history-by-employee/${employeeId}`).pipe(
			map((res) => {
				const response = new Response<TimeShiftHistoryModel[]>();
				response.data = res.body.map((pp) => TimeShiftHistoryModel.create(pp));
				response.count = res.headers.get('x-count');
				return response;
			})
		);
	}

	getSuggestSequence(id: string, employeeId: string, dateProjection: string): Observable<string> {
		return new Observable<string>((observer) => {
			this.http.get(`${this.endpoint}/${id}/suggest-sequence/${employeeId}/${dateProjection}`).subscribe(
				(res) => {
					console.log("Suggest Sequence", res.body);
					observer.next(res.body.sequence);
					observer.complete();
				},
				(err) => observer.error(err)
			);
		});
	}

	deleteTimeShiftHistory(timeShiftHistory: TimeShiftHistoryModel) {
		return this.http.post(`${this.endpoint}/delete-time-hift-history`, timeShiftHistory);
	}

	loadTimeShiftsByParams({ companyId, workScaleGroup }: { companyId: string, workScaleGroup: string }): Observable<TimeShiftModel[]> {
		return new Observable<any>((observer) => {

			const select = ["id", "title", "code", "companyId", "companyGroupId", "enable", "creationDate", "workScale", "workScaleGroup"].join(',');
			const filter = `$filter=(companyId eq ${companyId}) and (workScaleGroup eq '${workScaleGroup}')`;
			const orderBy = `$orderBy=code asc`;
			const queryUrl = [`$select=${select}`, orderBy, filter].join('&');

			this.http.get(`${this.endpoint}/odata?${queryUrl}`, null, null, null, true).subscribe((res) => {
				observer.next(res.body.filter((x) => x.enable).map(item => TimeShiftModel.create(item)));
			}, (err) => {
				observer.error(err);
			});
		});
	}
}
