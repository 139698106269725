import {
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';

import { AbstractControl } from '@angular/forms';
export class FormValidations {
  static getErrorMsg(
    fieldName: string,
    validatorName: string,
    validatorValue?: any
  ) {
    const config = {
      required: `Campo obrigatório.`,
      ngbDate: `Formato de data inválido`,
      mask: `Formato inválido`,
      cep: `Cep inválido`,
      cpf: `CPF inválido`,
      rg: `RG inválido`,
      cnpj: `CNPJ inválido`,
      cpfOrCnpj: `CPF/CNPJ inválido`,
      pis: `PIS inválido`,
      email: `Email inválido`,
      url: `Url inválida`,
      telefone: `Telefone inválido`,
      number: `O campo deve ser númerico`,
      min: `O valor mínimo é ${validatorValue?.min || validatorValue?.value}`,
      max: `O valor máximo é ${validatorValue?.max || validatorValue?.value}`,
      equalTo: `As senhas não conferem`,
      minlength: `O campo deve ter no mínimo ${validatorValue?.requiredLength}`,
      maxlength: `O campo deve ter no maximo ${validatorValue?.requiredLength}`,
      minLengthArray: `${validatorValue?.value}`,
      duplicate: `${validatorValue?.value}`,
      rangeLength: `O campo deve entre ${validatorValue?.value?.[0]} e ${validatorValue?.value?.[1]} caracteres`,
      filePdf: 'Somente arquivos em PDF',
      pdfSize: `Tamanho máximo 3 Mb, o arquivo atual tem ${validatorValue?.value} Mb`,
      fileLoja: `${validatorValue?.value}`,
      fileSize: `${validatorValue?.value}`,
      invalidDimensions: `${validatorValue?.value}`,
      minDate: 'Data inválida',
      maxDate: `Data inválida`,
      onlyInterge: 'Somente números inteiros',
      ccNumber: 'Número de cartão inválido',
      expDate: 'Data de vencimento inválida',
    };

    return config[validatorName];
  }

  static rangeLength(validatorValue) {
    if (validatorValue) {
      return `${validatorValue.value[0]} e ${validatorValue.value[1]}`;
    }

    return '';
  }

  static setRequiredIfEmpty(controls: Array<string>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.root as UntypedFormGroup;
      if (formGroup.controls) {
        controls.forEach((controlName) => {
          const target = formGroup.controls[controlName];
          if (control.value) {
            target.setValidators([FormValidations.file]);
          } else {
            target.setValidators([Validators.required, FormValidations.file]);
          }
          target.updateValueAndValidity();
        });
      }
      return null;
    };
  }

  static file(
    control: AbstractControl,
    maxlength: number = null
  ): ValidationErrors | null {
    const { extension = 'pdf', size = 0 } = control.value;
    const extValue = `${extension}`.toLowerCase();
    const extList = ['pdf', 'png', 'jpeg', 'jpg', 'jfif'];
    if (!extList.includes(extValue)) {
      return {
        fileLoja: {
          value: `Somente arquivos ${extList.join(' - ').toUpperCase()}`,
        },
      };
    }
    if (maxlength && size > maxlength) {
      return { fileSize: { value: Math.ceil(size / (1024 * 1024)) } };
    }
    return null;
  }

  static imageWithDimensions(control: AbstractControl, maxlength: number = 8): ValidationErrors | null {
    const { extension = 'png', size = 0, dimensions = {} } = control.value;
    const extValue = `${extension}`.toLowerCase();
    const extList = ['png', 'jpeg', 'jpg', 'jfif'];
  
    if (!extList.includes(extValue)) {
      control.markAsTouched();
      return {
        fileLoja: {
          value: `Somente imagens ${extList.join(' - ').toUpperCase()}`,
        },
      };
    }
  
    if (maxlength && size > maxlength) {
      control.markAsTouched();
      return { fileSize: { 
        value: `Tamanho máximo permitido: ${maxlength} Mb, o arquivo atual tem ${size} Mb.` } 
      };
    }
  
    const { width, height } = dimensions;
    const minWidth = 600;
    const minHeight = 400;
    const maxWidth = 1000;
    const maxHeight = 668;
  
    if (width && height && (width < minWidth || width > maxWidth || height < minHeight || height > maxHeight)) {
      control.markAsTouched();
      return {
        invalidDimensions: {
          value: `As dimensões da imagem devem estar entre ${minWidth}x${minHeight} e ${maxWidth}x${maxHeight}`,
        },
      };
    }
  
    return null;
  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) {
        return null;
      }

      return {
        minLengthArray: { value: `Adicione pelo meno ${min} item na lista` },
      };
    };
  }

  static duplicateItens(propertyNames: string[]) {
    const validator = (formArray: UntypedFormArray) => {
      const values = formArray.controls.map((control) => control.value);

      const properties = [];
      values.forEach((value) => {
        const tempArray = Object.entries(value)
          .filter(([k, v]) => propertyNames.includes(k))
          .map(([k, v]) => v);
        properties.push(tempArray);
      });

      const propertiesChanded = properties.map((v) => v.join(''));
      const hasDuplicate = propertiesChanded.some((line, index) =>
        propertiesChanded.includes(line, index + 1)
      );

      return hasDuplicate
        ? {
            duplicate: {
              value:
                propertyNames.length === 1
                  ? `o item ${propertyNames} não pode ser duplicado`
                  : `Os itens ${propertyNames} não podem ser duplicados`,
            },
          }
        : null;
    };

    return validator;
  }

  static removeFormControlError(control: AbstractControl, errorName: string) {
    if (control?.errors && control?.errors[errorName]) {
      delete control.errors[errorName];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  static guidRequired(control: AbstractControl) {
    if (control.value === '00000000-0000-0000-0000-000000000000') {
      return { required: Validators.required };
    }
    return null;
  }

  static cpfOrCnpj(control: AbstractControl): ValidationErrors | null {
    if (FormValidations.cpf(control) && FormValidations.cnpj(control)) {
      return { cpfOrCnpj: 'CPF/CNPJ inválido' };
    }
    return null;
  }

  static onlyInterge(control: AbstractControl): ValidationErrors | null {
    const value = `${control.value}`;
    const onlyNumber = value.replace(/\D/g, '');
    if (value !== onlyNumber) {
      return { onlyInterge: true };
    }
    return null;
  }

  static dateValidator(control: AbstractControl): ValidationErrors | null {
    let value = control.value;
    if (value && typeof value === 'string') {
      if (value == '0001-01-01T00:00:00.000' || value.length <= 1)
        return { dateInvalid: true };
      let match = value.match(
        /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
      );
      if (!match) {
        return { dateInvalid: true };
      } else if (match && match[0] !== value) {
        return { dateInvalid: true };
      }
    }
    return null;
  }

  static setRequiredIfValue(controlName: string, value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.root as UntypedFormGroup;
      if (formGroup.controls) {
        const target = formGroup.controls[controlName];
        if (`${control.value}` === `${value}`) {
          target.setValidators([Validators.required, Validators.min(1)]);
          target.enable();
        } else {
          target.disable();
          target.setValue('');
        }
        target.updateValueAndValidity();
      }
      return null;
    };
  }

  static cpf(control: AbstractControl): ValidationErrors | null {
    let soma = 0;
    let resto;

    if (control.value == '00000000000') {
      return { cpf: 'CPF inválido' };
    }

    for (let index = 1; index <= 9; index++) {
      soma =
        soma +
        parseInt(control.value.substring(index - 1, index)) * (11 - index);
    }

    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) {
      resto = 0;
    }
    if (resto != parseInt(control.value.substring(9, 10))) {
      return { cpf: 'CPF inválido' };
    }

    soma = 0;

    for (let index = 1; index <= 10; index++) {
      soma =
        soma +
        parseInt(control.value.substring(index - 1, index)) * (12 - index);
    }

    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) {
      resto = 0;
    }

    if (resto != parseInt(control.value.substring(10, 11))) {
      return { cpf: 'CPF inválido' };
    }

    return null;
  }

  static cnpj(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return { cnpj: 'CNPJ inválido' };
    }
  
    const cnpj = control.value.replace(/[^\d]+/g, '');
  
    if (cnpj == '') {
      return { cnpj: 'CNPJ inválido' };
    }
  
    if (cnpj.length != 14) {
      return { cnpj: 'CNPJ inválido' };
    }
  
   // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    ) {
      return { cnpj: 'CNPJ inválido' };
    }

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
  
      for (let index = tamanho; index >= 1; index--) {
        soma += numeros.charAt(tamanho - index) * pos--;
        if (pos < 2) pos = 9;
      }
  
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) {
        return { cnpj: 'CNPJ inválido' };
      }
  
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
  
      for (let index = tamanho; index >= 1; index--) {
        soma += numeros.charAt(tamanho - index) * pos--;
        if (pos < 2) pos = 9;
      }
  
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) {
        return { cnpj: 'CNPJ inválido' };
      }
  
      return null;
    }
  
  static pisValidator(control: AbstractControl): ValidationErrors | null {
    const pis = control.value;
    const multiplicadorBase = '3298765432';
    let total = 0;
    let resto = 0;
    let multiplicando = 0;
    let multiplicador = 0;
    let digito = 99;

    // Retira a mascara
    const numeroPIS = pis.replace(/[^\d]+/g, '');

    if (
      numeroPIS.length !== 11 ||
      numeroPIS === '00000000000' ||
      numeroPIS === '11111111111' ||
      numeroPIS === '22222222222' ||
      numeroPIS === '33333333333' ||
      numeroPIS === '44444444444' ||
      numeroPIS === '55555555555' ||
      numeroPIS === '66666666666' ||
      numeroPIS === '77777777777' ||
      numeroPIS === '88888888888' ||
      numeroPIS === '99999999999'
    ) {
      return { pis: 'PIS inválido' };
    } else {
      for (let i = 0; i < 10; i++) {
        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
        total += multiplicando * multiplicador;
      }

      resto = 11 - (total % 11);
      resto = resto === 10 || resto === 11 ? 0 : resto;

      digito = parseInt('' + numeroPIS.charAt(10));
      return null;
    }
  }
}
